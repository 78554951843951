<template>
	<article class="lp">
		<h1 class="lp__header"><img src="~@/assets/img/lp_temperaturedifference2022/lp_temperaturedifference_title.png" alt="その不調… 寒暖差疲労かも？ 簡単チェック＆5つの対策 presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<div class="lp__intro">
			<p class="lp__intro--text">季節の変わり目はいつも体調が悪い…<br>急な寒さや暑さに疲れを感じる…<br>そんな症状がある人は「寒暖差疲労」かもしれません。</p>
		</div>
		<!-- /イントロ -->

		<!-- STEP1 -->
		<section class="lp__contents is-step1">
			<h2 class="lp__contents--title">「寒暖差疲労」ってなに？</h2>
			<p class="lp__contents--intro">寒暖差疲労は気象病の１つ。<br>原因や症状、簡単にできる対策を、<br>専門医がご紹介します。</p>
			<div class="lp__contents--inner">
				<h3 class="lp__contents--subtitle">寒暖差疲労<br>原因と５つの対策</h3>
				<div class="lp__contents--img"><img src="~@/assets/img/lp_temperaturedifference2022/lp_temperaturedifference_01.png" alt="" class="img01"></div>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 756 } })" id="trackingTemperatureDifference2022Btn01" class="lp__contents--link">詳しくはコチラ</a>
			</div>
		</section>
		<!-- /STEP1 -->

		<!-- STEP2 -->
		<section class="lp__contents is-step2">
			<h2 class="lp__contents--title">あなたも「寒暖差疲労」かも？</h2>
			<p class="lp__contents--intro">あなたの身体は寒暖差に弱い？<br>まずはチェックしてみましょう！</p>
			<div class="lp__contents--inner">
				<h3 class="lp__contents--subtitle">【医師監修】<br> 寒暖差疲労セルフチェック</h3>
				<div class="lp__contents--img"><img src="~@/assets/img/lp_temperaturedifference2022/lp_temperaturedifference_02.png" alt="" class="img02"></div>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })" id="trackingTemperatureDifference2022Btn02" class="lp__contents--link">チェックをはじめる</a>
			</div>
		</section>
		<!-- /STEP2 -->

		<!-- STEP3 -->
		<section class="lp__contents is-step3">
			<h2 class="lp__contents--title">「自律神経」も大きく関係あり！</h2>
			<p class="lp__contents--intro">寒暖差疲労などの気象病は<br>自律神経が乱れると<br>症状が出やすくなることがあります。</p>
			<div class="lp__contents--inner">
				<h3 class="lp__contents--subtitle">自律神経を整えよう！</h3>
				<div class="lp__contents--box">
					<ul class="lp__contents--list">
						<li>簡単ストレッチ＆呼吸方法</li>
						<li>６つの悪習慣</li>
						<li>バランスチェック</li>
					</ul>
					<div class="lp__contents--img"><img src="~@/assets/img/lp_temperaturedifference2022/lp_temperaturedifference_03.png" alt="" class="img03"></div>
				</div>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpautonomicnerves2021' } })" id="trackingTemperatureDifference2022Btn03" class="lp__contents--link">詳しくみる</a>
			</div>
		</section>
		<!-- /STEP3 -->

		<!-- アウトロ -->
		<section class="lp__outro">
			<h2 class="lp__outro--title"><span>頭痛ーるで不調に<br>なりやすい日を知ろう！</span></h2>
			<p class="lp__outro--text">自律神経が乱れると、気圧や温度の変化で体調不良になる「気象病」の症状も出やすくなります。頭痛ーるは、不調の原因となる「気圧の変化」をお知らせします。</p>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="growthhack-f__with-zutool">
				<p class="growthhack-f__with-zutool--title">
					with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
				</p>
				<img src="~@/assets/img/lp_temperaturedifference2022/lp_temperaturedifference_meteoropathy.png" alt="" class="growthhack-f__with-zutool--img">
			</div>
			<div class="about-rightnow__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingTemperatureDifference2022Btn04_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingTemperatureDifference2022Btn04_ios">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
			<div class="lp__back">
				<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="about-rightnow__login--button">TOPにもどる</a>
			</div>
		</section>
		<!-- /アウトロ -->
	</article>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'LpTemperatureDifference2022',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// コンテンツの高さをemitする処理（実処理は親で行う）
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	this.$emit('contentHeight')
		// })
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";

// NOTE: LPページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-12: 0.75rem;
$spacing-14: 0.875rem;
$spacing-18: 1.125rem;
$spacing-22: 1.375rem;
$spacing-25: 1.562rem;
$spacing-28: 1.75rem;
$spacing-32: 2rem;
$spacing-40: 2.5rem;
$spacing-50: 3.125rem;
$font-size-15: 0.937rem;
$font-size-17: 1.0625rem;
$font-size-20: 1.25rem;
$line-height-133: 1.33;
$line-height-146: 1.46;
$lp-primary-color: #21498D;
$lp-secondary-color: #FF5400;
$lp-tertiary-color: #F48B56;
$lp-backgroud-color: #FFF6E2;

.lp {
	overflow: hidden;
	font-family: $lp-family-primary;
	background-color: $background-primary;
	line-height: $line-height-146;
	color: $text-primary;
	font-size: $font-size-15;
	max-width: 640px;
	margin: 0 auto;

	&__header {
		width: 94.6667%;
		margin: 0 auto;

		img {
			width: 100%;
			display: block;
		}
	}

	&__intro {
		margin: $spacing-18 0 0 0;
		padding: 0 $spacing-20;

		&--text { margin: 0; }
	}

	&__contents {
		padding: 0 $spacing-14;

		&.is-step1 { margin: $spacing-12 0 0 0; }
		&.is-step2 { margin: $spacing-32 0 0 0; }
		&.is-step3 { margin: $spacing-32 0 0 0; }

		&--inner {
			margin: $spacing-18 0 0 0;
			padding: $spacing-22 0 $spacing-28;
			border-radius: 10px;
			border: 2px solid $lp-secondary-color;
			background-color: $lp-backgroud-color;
		}

		&--title {
			position: relative;
			margin: 0;
			padding: $spacing-50 0 $spacing-25;
			font-size: $font-size-20;
			font-family: $lp-family-secondary;
			font-weight: normal;
			color: $lp-primary-color;
			text-align: center;
			line-height: $line-height-1;
			white-space: nowrap;

			@media (max-width: (340px)) { font-size: $font-size-16; white-space: normal; line-height: $line-height-133; }

			&::before {
				content: url(~@/assets/img/lp_temperaturedifference2022/lp_temperaturedifference_cloud.svg);
				display: block;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			&::after {
				content: "";
				border-radius: 4px;
				display: block;
				position: absolute;
				bottom: 4px;
				left: 50%;
				width: 100px;
				height: 6px;
				background-color: $lp-primary-color;
				transform: translateX(-50%);
			}
		}

		&--intro {
			margin: $spacing-14 0 0 0;
			text-align: center;
		}

		&--subtitle {
			margin: 0;
			color: $lp-secondary-color;
			text-align: center;
			font-size: $font-size-24;
			font-weight: bold;
			line-height: $line-height-133;

			@media (max-width: (340px)) { font-size: $font-size-20; }
		}

		&--img {
			img {
				display: block;

				&.img01 {
					margin: $spacing-8 auto;
					width: 52.77%;
					max-width: 100%;
				}

				&.img02 {
					margin: $spacing-2 auto 0;
					width: 61.225%;
					max-width: 100%;
				}

				&.img03 {
					margin: $spacing-6 auto 0;
					width: 35.338%;
					max-width: 106px;
					position: absolute;
					top: $spacing-12;
					right: 0;
				}
			}
		}

		&--box {
			position: relative;
			margin: $spacing-12 auto $spacing-32;
			width: 87.464%;
		}

		&--list {
			margin-bottom: $spacing-50;
			padding: 0;

			& > li {
				font-size: $font-size-17;
				margin: 0;
				line-height: $line-height-146;

				&::before {
					content: "\02605";
					color: $lp-tertiary-color;
				}
			}
		}

		&--link {
			display: block;
			width: 87.464%;
			margin: 0 auto 0;
			padding: $spacing-12 0;
			background-color: $lp-tertiary-color;
			border-radius: 6px;
			text-align: center;
			color: $text-seconday;
			font-size: $font-size-18;
			font-weight: bold;
			text-decoration: none;
			line-height: $line-height-1;
		}
	}

	&__outro {
		margin: $spacing-40 0 0 0;

		&--title {
			margin: 0;
			color: $primary;
			text-align: center;
			font-size: $font-size-20;

			@media (max-width: (340px)) { font-size: $font-size-18; }

			span {
				position: relative;
				font-weight: bold;

				&::before {
					content: "";
					border: 1px solid $primary;
					position: absolute;
					top: 0;
					left: -3em;
					height: 100%;
					transform: rotate(-30deg);
				}

				&::after {
					content: "";
					border: 1px solid $primary;
					position: absolute;
					top: 0;
					right: -1.4em;
					height: 100%;
					transform: rotate(30deg);
				}
			}
		}

		&--text {
			margin: $spacing-10 0 0 0;
			padding: 0 $spacing-14;
			line-height: $line-height;
		}

		.growthhack-f__caution { margin: $spacing-12 0 0 0; }
		.about-rightnow__login { padding-top: 0; }
		.growthhack-f__with-zutool--img { width: 112px; height: auto; }
	}

	&__back {
		margin: $spacing-20 0 0 0;

		.about-rightnow__login--button { font-weight: normal; }
	}
}
</style>
